// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$campaign-master-gui-primary: mat-palette($mat-indigo);
$campaign-master-gui-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$campaign-master-gui-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$campaign-master-gui-theme: mat-light-theme((color: (primary: $campaign-master-gui-primary,
        accent: $campaign-master-gui-accent,
        warn: $campaign-master-gui-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($campaign-master-gui-theme);

// Angular notifier styles
@import '~angular-notifier/styles';

html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.component-wrapper {
  padding: 4em 10em;
  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;

  &.master-spacing {
    margin: 1em 3em;
  }
}

label.invisible {
  display: none;
}

mat-form-field:not(.mat-form-field-should-float) .mat-input-placeholder {
  padding: 0.5em;
  box-sizing: border-box;
}

.flex-form {
  display: flex;

  .form-column {
    display: flex;
    flex-direction: column;

    &.space-between {
      justify-content: space-between;
    }
  }
}

.create-campaign,
.create-action {
  width: 15em;
  display: flex;
  justify-content: space-between;
}

.none-placeholder-msg {
  color: #a19f9f;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  padding-top: 2em;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button {
  background-color: #4688D8;
  border: 0;
  color: #fff;
  padding: 7px 10px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  span {
    margin-top: 2px;
    margin-left: 2px;
  }

  &:hover {
    background-color: #0073ff;
  }
}

.create-button {
  margin-left: 10px;
}

select,
input[type="text"],
input[type="time"],
input[type="date"] {
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 30px;
}

select,
input[type="radio"],
input[type="checkbox"] {
  height: auto;
}

textarea {
  margin-bottom: 5px;
}

.mat-icon {
  font-size: 14pt;
  height: auto !important;
  width: auto !important;
}

.multiselect-dropdown {
  .dropdown-btn {
    border: 1px solid !important;
    border-radius: 2px !important;
    padding: 2px 12px !important;
  }

  .dropdown-multiselect__caret {
    height: 30px !important;
  }

  .filter-textbox {
    padding: 4px !important;
  }
}

